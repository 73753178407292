@use '../../../../node_modules/bootstrap/scss/bootstrap' as *;
@use 'variables' as *;
@use 'mixins' as *;

@include media-breakpoint-down(md) {
    main {
        @include vertical-center;
    }
}

@include media-breakpoint-up(md) {
    main {
        @include full-center;
    }
}

@include media-breakpoint-up(lg) {}
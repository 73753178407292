@mixin us-link {
    a {
        color: $umbrielblue !important;
        text-decoration: none;

        &:hover,
        &:active {
            color: lighten($umbrielblue, 20%) !important;
        }
    }
}

@mixin light-link {
    a {
        color: white;
        text-decoration: none;

        &:hover,
        &:active {
            transition: color .15s ease-in-out;
            color: darkgray;
        }
    }
}

@mixin full-center {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@mixin vertical-center {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
}
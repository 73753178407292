@use '../../../../node_modules/bootstrap/scss/bootstrap';

@use "variables" as *;
@use "mixins" as *;
@use "media";
@use "common";
@use "fonts";

html,
body {
    margin: 0;

    @extend .bg-darker;
}

/* width */
::-webkit-scrollbar {
    padding-left: 0;
    width: 10px;
    margin-right: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: $secondary;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: lighten($umbrielblue, 5%);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: lighten($umbrielblue, 10%);
}

header {
    height: 64px;
}

main {}

footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 165px;
a {
        color: white;
        transition: color 0.1s ease-in-out;

        &:hover {
            color: $umbrielblue;
        }
    }
}